import React from "react";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

const PreviewWrapper = styled.div`
  text-align: center;

  .payment-icons {
    display: flex;
    gap: 12px;
    margin-top: 10px;
  }

  .payment-icon {
    width: 32px;
    height: 24px;
    object-fit: contain;
  }
`;

export default function PaymentIconsContentPreview({ content }) {
  let fontSize = "0.875rem"
  switch (content.size) {
    case "extraSmall":
      fontSize = "0.625rem"
      break
    case "small":
      fontSize = "0.75rem"
      break
    case "base":
      fontSize = "0.875rem"
      break
    case "medium":
      fontSize = "1rem"
      break
    case "large":
      fontSize = "1.1875rem"
      break
    case "extraLarge":
      fontSize = "1.5rem"
      break
  }
  let color = "#625A5F"
  switch (content.color) {
    case "success":
      color = "#76A36A"
      break
    case "info":
      color = "#5E94CD"
      break
    case "warning":
      color = "#F3D082"
      break
    case "critical":
      color = "#D24E4C"
      break
  }
  
  return (
    <PreviewWrapper>
      <div
        style={{
          marginBottom: "10px",
          textAlign: content.alignment,
          color: color,
          fontSize: fontSize
        }}
      >
        {content.contentText || "We accept the following payment methods"}
      </div>
      <div
        className="payment-icons"
        style={{
          justifyContent: content.alignment,
          fontSize: fontSize,
          color: color,
        }}
      >
        {content.paymentIcons?.map((icon, index) => {
          return <img src={icon.iconUrl} alt={icon.label} className="payment-icon" />
        })}
      </div>
    </PreviewWrapper>
  )
}
